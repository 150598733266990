import { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { InputAdornment, Box, Stack } from '@mui/material'
import { Search } from '@mui/icons-material'
import { getQuestions } from 'services'
import { capitalizeWordsOnSentence, findInWord, orderArray } from 'helpers'
import { useDebounce } from 'hooks'
import { SwitchIos } from 'components'
import {
  SearchInput,
  Question,
  SearchBox,
  TagGroup,
  SwitchText,
  LargeTooltip,
  AssistText,
  AssistTextContainer,
  NoResultsText,
} from './tags-filter-styles'

interface TFilterProps {
  setSelectedTag: (arg: any) => void
  tags: any[]
  setActiveHistory?: any
  disabledHistory?: boolean
  questionTextConfig?: string
  setHighlightTagQuestion: (arg: any) => void
}

function TagsFilter({
  tags,
  setSelectedTag,
  setActiveHistory,
  disabledHistory,
  questionTextConfig = 'questionSelfM',
  setHighlightTagQuestion,
}: TFilterProps): JSX.Element {
  const [list, setList] = useState<any>([])
  const [filteredList, setFilteredList] = useState<any>([])
  const [showList, setShowList] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState('')

  const inputRef = useRef<any>(null)
  const debounceSearch = useDebounce(searchTerm, 500)
  const { watch, control } = useForm({
    defaultValues: { activeHistory: !disabledHistory },
  })

  const activeHistory = watch('activeHistory')

  useEffect(() => {
    setActiveHistory(activeHistory)
  }, [activeHistory, setActiveHistory])

  useEffect(() => {
    const fetchData = async () => {
      const questions = await getQuestions()

      const activeQuestions = questions.filter(({ isActive }: any) => isActive)

      // build list
      const tagsWithQuestions = []
      for (const tag of tags) {
        const tagQuestions: any = []
        activeQuestions.forEach((question: any) => {
          if (question.tags.includes(tag.name)) {
            tagQuestions.push(question)
          }
        })
        delete tag.formTags
        tagsWithQuestions.push({ ...tag, questions: tagQuestions })
      }
      setList(orderArray(tagsWithQuestions, 'name'))
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  useEffect(() => {
    const filter = (filter: string = '') => {
      if (filter) {
        return setFilteredList(
          list
            .map((tag: any) => {
              const fQuestions = tag.questions.filter((question: any) => {
                return findInWord(question[questionTextConfig], filter)
              })
              return { ...tag, questions: fQuestions }
            })
            .filter((tag: any) => {
              return tag.questions.length
            })
        )
      }
      return setFilteredList(list)
    }

    filter(debounceSearch)
  }, [debounceSearch, list, questionTextConfig])

  const handleTagClick = ({ name, id }: any) => {
    inputRef.current.blur()
    setSelectedTag({ id, name, value: name, expanded: true })
  }

  const handleQuestionClick = ({ tag, question }: any) => {
    inputRef.current.blur()
    setHighlightTagQuestion({ tag, question })
  }

  return (
    <Stack width='540px' position='relative'>
      <AssistTextContainer>
        <AssistText>ASSISTENTE DE SELEÇÃO</AssistText>
      </AssistTextContainer>
      <Box
        width='540px'
        height='64px'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        px={2}
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '37px',
          border: '1px solid #BDC1DD',
        }}
      >
        <Stack direction='row' spacing={1} alignItems='center'>
          <LargeTooltip
            title={
              disabledHistory
                ? 'Disponível quando o paciente responder pelo menos um questionário'
                : ''
            }
            arrow
            placement='top'
          >
            <span>
              <SwitchIos
                name='activeHistory'
                control={control}
                disabled={disabledHistory}
              />
            </span>
          </LargeTooltip>
          <SwitchText disabled={disabledHistory}>
            Histórico de respostas
          </SwitchText>
        </Stack>

        {/* COMPONENTE DE BUSCA */}
        <Box width='286px'>
          <SearchInput
            inputRef={inputRef}
            onChange={(e: any) => {
              setHighlightTagQuestion(null)
              setSearchTerm(e.target.value)
            }}
            type='text'
            size='small'
            autoComplete='off'
            onFocus={() => setShowList(true)}
            onBlur={() => setShowList(false)}
            placeholder='Buscar perguntas'
            startAdornment={
              <InputAdornment position='start'>
                <Search style={{ fill: '#BDBDBD' }} />
              </InputAdornment>
            }
            showList={
              (showList && debounceSearch.length > 2) ||
              (Boolean(searchTerm.length) && searchTerm.length < 3)
            }
          />
          {Boolean(searchTerm.length) && searchTerm.length < 3 && (
            <SearchBox px={2}>
              <Box py={1}>
                <Question p={'2px'}>Mínimo 3 caracteres</Question>
              </Box>
            </SearchBox>
          )}
          {showList && debounceSearch.length > 2 && (
            <>
              {!!filteredList.length ? (
                <SearchBox px={2}>
                  {filteredList.map((tag: any) => {
                    return (
                      <Box
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => handleTagClick(tag)}
                        key={tag.id}
                        py={1}
                      >
                        <TagGroup py={'2px'} px={'5px'} my={'10px'}>
                          {capitalizeWordsOnSentence(tag.name)}
                        </TagGroup>
                        {tag.questions.map((question: any) => (
                          <Question
                            key={question.id}
                            p={'2px'}
                            onClick={(e: any) => {
                              e.stopPropagation()
                              handleTagClick(tag)
                              handleQuestionClick({ tag, question })
                            }}
                          >
                            {question[questionTextConfig]
                              .split(searchTerm)
                              .reduce(
                                (
                                  acc: any,
                                  value: string,
                                  index: number,
                                  array: any
                                ) => {
                                  return (
                                    <>
                                      <span>{acc}</span>
                                      <span>{value}</span>
                                      {index !== array.length - 1 && (
                                        <Question highlight>
                                          {searchTerm}
                                        </Question>
                                      )}
                                    </>
                                  )
                                },
                                <></>
                              )}
                          </Question>
                        ))}
                      </Box>
                    )
                  })}
                </SearchBox>
              ) : (
                <SearchBox px={2}>
                  <Box
                    pt={2}
                    pb={5}
                    display='flex'
                    width='100%'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <NoResultsText p={'2px'}>
                      Nenhum resultado encontrado
                    </NoResultsText>
                  </Box>
                </SearchBox>
              )}
            </>
          )}
        </Box>
      </Box>
    </Stack>
  )
}

export default TagsFilter
