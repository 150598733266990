import { useState, useEffect, useRef, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  Typography,
  Box,
  CircularProgress,
  Tooltip,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from '@mui/material'
import { ExpandMore, InfoOutlined } from '@mui/icons-material'
import { ButtonClinic, SelectQuestions, TagsFilter } from 'components'
import { useInvitePatientContext, useAuthContext } from 'contexts'
import { useNotification, useQuery } from 'hooks'
import { getQuestions, getLastForm, getTags, getUserResponses } from 'services'
import { newQuestionnaireQuestionsStepResolver } from 'resolvers'
import {
  capitalizeWordsOnSentence,
  extractTagsFromQuestions,
  filterMultipleTags,
  orderArray,
  registerPageAccess,
  registerTrack,
  sortTagQuestionsByOrder,
} from 'helpers'
import {
  TagName,
  TagContainer,
  TagBox,
  SupportText,
  TagsTitle,
  TooltipText,
  EmptyText,
} from './questions-step-styles'
import { Title } from '../styles/common-styles'

function QuestionStep(): JSX.Element {
  const [tags, setTags] = useState<any>([])
  const [databaseTags, setDatabaseTags] = useState<any>([])
  const [questions, setQuestions] = useState<any>([])
  const [selectedTags, setSelectedTags] = useState<any>([])
  const [shownTags, setShownTags] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [hasDuplicatedQuestions, setHasDuplicatedQuestions] = useState(false)
  const [patientResponses, setPatientResponses] = useState([])
  const [activeHistory, setActiveHistory] = useState<boolean>(false)
  const [disabledHistory, setDisabledHistory] = useState<boolean>(true)
  const [highlightTagQuestion, setHighlightTagQuestion] = useState<any>()

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      selectedQuestions: [],
    },
    resolver: newQuestionnaireQuestionsStepResolver,
  })
  const {
    goNextStep,
    setNewQuestionnaireInfos,
    newQuestionnaireInfos,
    selectedTag,
    setSelectedTag,
  } = useInvitePatientContext()
  const tagsRef: any = useRef([])
  const query = useQuery()
  const { errorToast } = useNotification()
  const { user } = useAuthContext()

  const selectedQuestions = watch('selectedQuestions')
  const counter = useMemo(() => selectedQuestions.length, [selectedQuestions])
  const selectedTagsValues = selectedTags?.map((tag: any) => tag.value) || []

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = query.get('user_id')

        const questions = await getQuestions()
        const databaseTags = await getTags()

        if (userId) {
          const patientResponses: any = await getUserResponses(userId!)

          setPatientResponses(patientResponses)

          if (patientResponses) {
            const hasResponses = !Object.keys(patientResponses)?.length

            setDisabledHistory(hasResponses)
          }
        }

        setDatabaseTags(databaseTags)

        const activeQuestions = questions.filter(
          ({ isActive }: any) => isActive
        )

        if (!activeQuestions)
          throw new Error('Ocorreu um erro ao buscar as perguntas')

        setTags(orderArray(extractTagsFromQuestions(activeQuestions), 'label'))
        setQuestions(activeQuestions)

        const lastFormId = query.get('last_form_id')

        const hasMemorizedSelectedQuestions = Boolean(
          newQuestionnaireInfos.selectedQuestions.length
        )

        if (hasMemorizedSelectedQuestions) {
          setValue('selectedQuestions', newQuestionnaireInfos.selectedQuestions)
          if (Boolean(newQuestionnaireInfos.selectedTags?.length)) {
            setSelectedTags(newQuestionnaireInfos.selectedTags)
          } else {
            setSelectedTags(
              extractTagsFromQuestions(newQuestionnaireInfos.selectedQuestions)
            )
          }
          return
        }

        if (lastFormId) {
          const { questions: lastFormQuestions, tags: lastFormTags } =
            await getLastForm(lastFormId)

          if (!lastFormQuestions) return

          const activeLastFormQuestions = lastFormQuestions.filter(
            ({ isActive }: any) => isActive
          )

          if (lastFormTags?.length === 0) {
            const tagsFromQuestions = extractTagsFromQuestions(
              activeLastFormQuestions
            )

            const filteredTags = filterMultipleTags(
              tagsFromQuestions,
              activeLastFormQuestions
            )

            setSelectedTags(filteredTags)
          } else {
            const lastSelectedTags = lastFormTags.map((lastFormTag: any) => {
              const total = activeQuestions.filter((question: any) =>
                question.tags.includes(lastFormTag.tag)
              ).length

              return {
                value: lastFormTag.tag,
                label: `${lastFormTag.tag} (${total})`,
                name: lastFormTag.tag,
                total: total,
              }
            })
            const lastSelectedTagsNames = lastSelectedTags?.map(
              (tag: any) => tag.value
            )
            setSelectedTags(lastSelectedTags)

            activeLastFormQuestions.forEach((question: any) => {
              for (const tag of question.tags) {
                if (lastSelectedTagsNames.includes(tag)) {
                  question.tag = lastSelectedTags.find(
                    (t: any) => t.value === tag
                  )
                }
              }
            })
          }
          setValue('selectedQuestions', activeLastFormQuestions)
        }
      } catch (error: any) {
        console.error(error)
        errorToast(error.message)
      } finally {
        setSelectedTags((prevTags: any) =>
          orderArray(prevTags, 'name').map((t: any) => ({
            ...t,
            expanded: true,
          }))
        )
        setLoading(false)
      }
    }
    registerPageAccess('Selecao de Perguntas', {
      clinic_id: user.clinicId,
      patient_id: query.get('patient_id'),
    })

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (errors?.selectedQuestions) errorToast(errors.selectedQuestions.message)
  }, [errors.selectedQuestions, errorToast])

  useEffect(() => {
    setShownTags(orderArray(selectedTags, 'name'))
  }, [selectedTags])

  useEffect(() => {
    if (selectedTag) {
      const tagChecked = selectedTags.some(
        (tag: any) => tag.value === selectedTag.value
      )

      if (tagChecked) {
        const newTags = selectedTags.filter(
          (t: any) => t.value !== selectedTag.value
        )
        setShownTags(orderArray([selectedTag, ...newTags], 'name'))
      } else {
        setShownTags(orderArray([selectedTag, ...selectedTags], 'name'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag])

  useEffect(() => {
    const hasDupQuestions = !!selectedQuestions.filter((q: any) => {
      return (
        q.tags.filter((t: any) => selectedTagsValues.includes(t)).length > 1
      )
    }).length

    return setHasDuplicatedQuestions(hasDupQuestions)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestions])

  useEffect(() => {
    if (selectedTag?.scrollfocus) {
      setTimeout(() => {
        scrollToTag(selectedTag.value)
      }, 200)
      setSelectedTag((tag: any) => ({ ...tag, scrollfocus: false }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownTags])

  const onSubmit = (data: any) => {
    setNewQuestionnaireInfos((newQuestionnaireInfos: any) => ({
      ...newQuestionnaireInfos,
      selectedQuestions: data.selectedQuestions,
      selectedTags,
      sendTo: query.get('user_id'),
    }))

    goNextStep()
  }

  const scrollToTag = (tagValue: string) => {
    const tagIndex = shownTags.map((e: any) => e.value).indexOf(tagValue)
    tagsRef.current[tagIndex]?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const handleTagByQuestion = (question: any, checked: any) => {
    const { tag } = question
    registerTrack(`${checked ? 'Adiciona' : 'Remove'} Pergunta`, {
      identifier: question.identifier,
      patient_id: query.get('patient_id'),
      value: checked,
    })
    const newSelectedQuestions = checked
      ? [...selectedQuestions, question]
      : selectedQuestions.filter(
          (oldQuestion: any) => oldQuestion.id !== question.id
        )

    setValue('selectedQuestions', newSelectedQuestions)

    const isChecked = selectedTags.some((sTag: any) => tag.value === sTag.value)

    const tagQuestions = newSelectedQuestions.filter((question: any) => {
      question.tag ??= tag
      return (
        question.tags.includes(tag.value) && question.tag.value === tag.value
      )
    }).length

    if (isChecked && tagQuestions === 0) {
      const newTags = selectedTags.filter((t: any) => t.value !== tag.value)
      setSelectedTags(newTags)
      return
    } else if (!isChecked) {
      setSelectedTags((tags: any) => orderArray([...tags, tag], 'name'))
    }

    if (selectedTag?.value !== tag.value) {
      setSelectedTag(tag)
    }
  }

  const handleSelectTagQuestions = (tag: any) => {
    const tagQuestions = questions
      .filter(({ id, tags }: any) => {
        return (
          tags.some((tagName: any) => tagName === tag.value) &&
          !selectedQuestions.some((question: any) => question.id === id)
        )
      })
      .map((question: any) => ({ ...question, tag }))

    setValue('selectedQuestions', [...selectedQuestions, ...tagQuestions])
  }

  const handleRemoveTag = (tag: any) => {
    const idsToRemove = selectedQuestions
      .filter((question: any) => {
        question.tag ??= tag
        return (
          question.tags.includes(tag.value) && question.tag.value === tag.value
        )
      })
      .map((question: any) => question.id)

    // move duplicated questions to another tag
    const changedQuestions = selectedQuestions
      .filter((question: any) => {
        question.tag ??= tag
        return (
          question.tags.includes(tag.value) && question.tag.value === tag.value
        )
      })
      .filter((q: any) => {
        for (const qT of q.tags) {
          if (qT !== tag.value && selectedTagsValues.includes(qT)) {
            q.tag = tags.find((t: any) => t.value === qT)
            return true
          }
        }
        return false
      })

    const newQuestions = selectedQuestions
      .filter((question: any) => !idsToRemove.includes(question.id))
      .concat(changedQuestions)

    setValue('selectedQuestions', newQuestions)
    if (tag.value === selectedTag?.value) {
      setSelectedTag(null)
    }
  }

  const questionTextConfig = useMemo(
    () =>
      'question' +
      (newQuestionnaireInfos.questionsPerson === 'third' ? '3rd' : 'Self') +
      (newQuestionnaireInfos.questionsPronoun === 'she' ? 'F' : 'M'),

    [
      newQuestionnaireInfos.questionsPerson,
      newQuestionnaireInfos.questionsPronoun,
    ]
  )

  const patientName = useMemo(() => {
    return newQuestionnaireInfos.patientName?.split(' ')[0] || 'o paciente'
  }, [newQuestionnaireInfos.patientName])

  if (loading) {
    return <LoadingContainer />
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='space-between'
      justifyContent='space-between'
      height='100%'
      mb={5}
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* HEADER */}
      <Box
        display='flex'
        justifyContent='space-between'
        width='100%'
        px={7}
        my={3}
      >
        <HeaderInfos patientName={patientName} />

        <TagsFilter
          tags={databaseTags}
          setSelectedTag={setSelectedTag}
          setActiveHistory={setActiveHistory}
          disabledHistory={disabledHistory}
          questionTextConfig={questionTextConfig}
          setHighlightTagQuestion={setHighlightTagQuestion}
        />
      </Box>

      {/* CONTAINER DAS PERGUNTAS */}
      <Box sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.06)' }} display='flex'>
        {/*   TAGS   */}
        <Box
          py={3}
          width='356px'
          sx={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}
        >
          <TagsTitle sx={{ paddingX: 5 }}>Domínios</TagsTitle>
          <Box display='flex' flexDirection='column'>
            {tags.map((tag: any) => {
              const selected = selectedTag?.value === tag.value

              const checked = selectedTags.some(
                (sTag: any) => tag.value === sTag.value
              )

              const handleSelectTag = () => {
                setSelectedTag({
                  ...tag,
                  expanded: true,
                  scrollfocus: true,
                })
              }

              return (
                <TagContainer
                  key={tag.value}
                  onClick={handleSelectTag}
                  selected={selected}
                >
                  <TagBox checked={checked}>
                    <TagName>{capitalizeWordsOnSentence(tag.name)}</TagName>
                  </TagBox>
                </TagContainer>
              )
            })}
          </Box>
        </Box>

        {/*   QUESTIONS   */}

        <Box
          px={5}
          py={3}
          display='flex'
          flexGrow={1}
          flexDirection='column'
          height='100%'
          justifyContent={selectedTag ? 'space-between' : 'flex-end'}
        >
          {!shownTags.length ? (
            <Box px={2} mt={24}>
              <EmptyText>
                Navegue pelo menu à esquerda para visualizar e escolher as
                perguntas que deseja incluir.
              </EmptyText>
              <EmptyText>
                Você pode selecionar perguntas individuais ou inserir domínios
                completos.
              </EmptyText>
            </Box>
          ) : (
            <Box height='70vh' overflow='auto'>
              {/* Selected Questions */}
              {shownTags?.map((tag: any, i: number) => {
                const orderTagId = databaseTags.find(
                  ({ name }: any) => name === tag.value
                )?.id

                const tagQuestions = sortTagQuestionsByOrder(
                  questions.filter((question: any) =>
                    question.tags.includes(tag.value)
                  ),
                  orderTagId
                )

                const questionsSelected = selectedQuestions.filter(
                  (question: any) => question.tags.includes(tag.value)
                ).length

                const hasQuestions = questionsSelected > 0
                const isSelected = !!selectedTags.find(
                  (t: any) => t.value === tag.value
                )

                const isIndeterminate =
                  isSelected &&
                  hasQuestions &&
                  tagQuestions.length !== questionsSelected

                const onExpand = (e: any, expanded: boolean) => {
                  setShownTags((prev: any) => {
                    const newTag = prev.find((t: any) => t.value === tag.value)
                    newTag.expanded = expanded
                    return [...prev]
                  })
                }

                const handleSelectClick = (e: any) => {
                  const { checked } = e.target
                  registerTrack(`${checked ? 'Adiciona' : 'Remove'} Dominio`, {
                    clinic_id: user.clinicId,
                    patient_id: query.get('patient_id'),
                    domain_name: tag.value,
                  })
                  if (checked) {
                    setSelectedTag(tag)
                    setSelectedTags((tags: any) =>
                      orderArray([...tags, tag], 'name')
                    )
                    handleSelectTagQuestions(tag)
                  } else {
                    setSelectedTags((tags: any) =>
                      tags.filter((t: any) => t.value !== tag.value)
                    )
                    handleRemoveTag(tag)
                  }
                }

                return (
                  <Box ref={(el) => (tagsRef.current[i] = el)} key={i}>
                    <Box px={1} display={'flex'} alignItems={'start'}>
                      <Checkbox
                        checked={isSelected}
                        indeterminate={isIndeterminate}
                        onChange={handleSelectClick}
                        sx={{
                          marginY: '3px',
                          p: '8px',
                          color: 'rgba(0, 0, 0, 0.6)',
                          '&.Mui-checked': {
                            color: '#8D92BE',
                          },
                        }}
                      />
                      <Accordion
                        disableGutters
                        expanded={tag.expanded || false}
                        onChange={onExpand}
                        sx={{
                          boxShadow: 'none',
                          '.MuiCollapse-root': { transition: 'none' },
                          '.MuiAccordionSummary-expandIconWrapper': {},
                          '&::before': { content: 'none' },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          sx={{
                            p: 0,
                            justifyContent: 'start',
                            '.MuiAccordionSummary-content': {
                              flexGrow: 0,
                              mr: 1,
                            },
                            svg: {
                              transform: 'rotate(270deg)',
                            },
                            '.Mui-expanded svg': {
                              transform: 'rotate(180deg)',
                            },
                          }}
                        >
                          <Typography>
                            {`${capitalizeWordsOnSentence(tag.name)} ${
                              selectedTagsValues.includes(tag.value) &&
                              questionsSelected > 0
                                ? `(${questionsSelected})`
                                : ''
                            }`}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0, pb: 2 }}>
                          <SelectQuestions
                            selectedTag={tag}
                            handleTagByQuestion={handleTagByQuestion}
                            questions={tagQuestions}
                            selectedQuestions={selectedQuestions}
                            name='selectedQuestions'
                            control={control}
                            patientResponses={patientResponses}
                            activeHistory={activeHistory}
                            questionTextConfig={questionTextConfig}
                            highlightTagQuestion={highlightTagQuestion}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          )}
        </Box>

        {Boolean(shownTags.length) && (
          <SendButton
            counter={counter}
            hasDuplicatedQuestions={hasDuplicatedQuestions}
          />
        )}
      </Box>
    </Box>
  )
}

// TODO separar arquivos

// * COMPONENTE DE BOTÃO FLUTUANTE PARA AVANÇAR
function SendButton({
  counter,
  hasDuplicatedQuestions,
}: {
  counter: number
  hasDuplicatedQuestions: boolean
}): JSX.Element {
  return (
    <Box
      display='flex'
      justifyContent='end'
      position='fixed'
      right={108}
      bottom={66}
    >
      <ButtonClinic
        variant='contained'
        size='small'
        fontWeight='regular'
        type='submit'
        color='purple'
        disabled={counter < 1}
        width={hasDuplicatedQuestions ? 277 : 245}
      >
        {`Continuar ${
          counter ? `com ${counter} pergunta${counter < 2 ? '' : 's'}` : ''
        }`}
        {hasDuplicatedQuestions && (
          <Tooltip
            title={
              <Stack>
                <TooltipText>Existem perguntas em comum </TooltipText>
                <TooltipText>entre os domínios selecionados</TooltipText>
              </Stack>
            }
            arrow
            placement='top'
          >
            <InfoOutlined fontSize='small' sx={{ ml: 1, color: '#FFF' }} />
          </Tooltip>
        )}
      </ButtonClinic>
    </Box>
  )
}

// * LOADING DA TELA
function LoadingContainer(): JSX.Element {
  return (
    <Box
      display='flex'
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems='center'
      justifyContent='center'
      height='100%'
      flexGrow={1}
    >
      <CircularProgress size={80} sx={{ color: '#8D92BE' }} />
    </Box>
  )
}

// * Titulo e texto de apoio do HEADER
function HeaderInfos({ patientName }: { patientName: string }): JSX.Element {
  return (
    <Stack alignItems='flex-start' spacing={1}>
      <Title>Crie um questionário para {patientName}</Title>
      <Stack alignItems='flex-start'>
        <SupportText>
          Utilize o banco de perguntas do Wida para criar
        </SupportText>
        <SupportText>
          questionários personalizados para seus pacientes.
        </SupportText>
      </Stack>
    </Stack>
  )
}

export default QuestionStep
